<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_user") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                  @click="save()"
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  type="success"
              >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                  $t("message.close")
                }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content">
            <div class="imgUpload">
              <div class="wrapper-block">
                <label for="">
                  <input type="file" @change="uploadImage($event)" id="file" />
                </label>
              </div>
              <div id="custom-upload" v-if="checkPhoto">
                <img src="" id="image" />
              </div>

              <!-- <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog> -->
            </div>

            <el-form-item :label="columns.name.title" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item :label="columns.surname.title" prop="surname">
              <el-input v-model="form.surname"></el-input>
            </el-form-item>
            <el-form-item :label="columns.patronymic.title" prop="patronymic">
              <el-input v-model="form.patronymic"></el-input>
            </el-form-item>

            <el-form-item :label="columns.specialties.title">
              <el-select
                  v-model="specialties"
                  multiple
                  filterable
                  :placeholder="columns.specialties.title"
              >
                <el-option
                    v-for="item in specialties_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.email.title" prop="email">
              <el-input v-model="form.email" placeholder="mail@"></el-input>
            </el-form-item>
            <el-form-item :label="columns.phone.title" prop="phone">
              <el-input
                  v-model="form.phone"
                  v-mask="'+999999999999'"
                  placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
            <el-form-item :label="columns.phone_two.title" prop="phone_two">
              <el-input
                  v-model="form.phone_two"
                  placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
            <el-form-item :label="columns.password.title" prop="password">
              <el-input
                  v-model="form.password"
                  :placeholder="$t('message.please_enter_parol')"
                  show-password
              ></el-input>
            </el-form-item>

            <!-- <el-form-item :label="columns.doctor_services.title">
                            <el-select v-model="DoctorServices" multiple filterable :placeholder="columns.doctor_services.title">
                                <el-option v-for="item in services_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item> -->
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.gender.title" prop="gender">
              <el-select v-model="form.gender" :placeholder="columns.gender.title">
                <el-option label="Н/О" value="no"></el-option>
                <el-option :label="$t('message.male')" value="men"></el-option>
                <el-option
                    :label="$t('message.female')"
                    value="women"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="columns.id_code.title" prop="id_code">
              <el-input
                  v-model="form.id_code"
                  :placeholder="$t('message.code_id')"
              ></el-input>
            </el-form-item>

            <div class="d-flex align-items-center">
              <el-form-item :label="columns.role_id.title" prop="role_id">
                <el-select
                    v-model="form.role_id"
                    :placeholder="$t('message.please_choose_role')"
                >
                  <el-option
                      v-for="role in roles"
                      :key="role.id"
                      :label="role.name"
                      :value="role.id"
                  ></el-option>
                  <!-- <el-option label="Doctor"     value="doctor"></el-option> -->
                </el-select>
              </el-form-item>
              <!-- <el-button class="minadd ml10"  @click="tabAddRole = true" icon="el-icon-circle-plus-outline"></el-button> -->
            </div>

            <el-form-item :label="columns.owner_branch_id.title">
              <el-select
                  v-model="form.owner_branch_ids"
                  filterable
                  multiple
                  clearable
                  :placeholder="columns.owner_branch_id.title"
              >
                <el-option
                    v-for="item in ownerBranches"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="columns.branch_id.title">
              <el-select
                  v-model="form.branch_ids"
                  multiple
                  filterable
                  clearable
                  :placeholder="columns.branch_id.title"
              >
                <el-option
                    v-for="item in branches"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <!-- end col -->
<!--        <el-col :span="24">-->
<!--          <el-tree-->
<!--              class="filter-tree"-->
<!--              :data="data"-->
<!--              node-key="id"-->
<!--              :filter-node-method="filterNode"-->
<!--              ref="items"-->
<!--              @node-click="handleNodeClick"-->
<!--              :expand-on-click-node="true"-->
<!--              show-checkbox-->
<!--          >-->
<!--            <span class="custom-tree-node" slot-scope="{ node, data }">-->
<!--              <span class="aaa my-tree-khan">-->
<!--                <div>{{ data.name }}</div>-->
<!--                <div style="display: flex">-->
<!--                  <el-select-->
<!--                      :placeholder="$t('message.class')"-->
<!--                      v-model="data.amount_type"-->
<!--                      @change="changes(node, data)"-->
<!--                      :disabled="!node.checked"-->
<!--                      size="small"-->
<!--                      class="my-kh ml-3"-->
<!--                      style=""-->
<!--                  >-->
<!--                    <el-option-->
<!--                        v-for="item in classes"-->
<!--                        :key="item.name"-->
<!--                        :label="item.name"-->
<!--                        :value="item.type"-->
<!--                    >-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                  <input-->
<!--                      :disabled="!data.amount_type"-->
<!--                      v-model="data.amount"-->
<!--                      @change="changes(node, data)"-->
<!--                      :placeholder="'sum'"-->
<!--                      ref="'data_amount'+ node.id"-->
<!--                      class="my-kh el-input-user"-->
<!--                      size="small"-->
<!--                  />-->
<!--                  <el-select-->
<!--                      :placeholder="$t('message.class')"-->
<!--                      v-model="data.secondary_amount_type"-->
<!--                      @change="secondaryChanges(node, data)"-->
<!--                      size="small"-->
<!--                      class="my-kh ml-3"-->
<!--                      style=""-->
<!--                  >-->
<!--                    <el-option-->
<!--                        v-for="item in classes"-->
<!--                        :key="item.name"-->
<!--                        :label="item.name"-->
<!--                        :value="item.type"-->
<!--                    >-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                  <input-->
<!--                      v-model="data.secondary_amount"-->
<!--                      @change="secondaryChanges(node, data)"-->
<!--                      :placeholder="'sum'"-->
<!--                      ref="'data_amount'+ node.id"-->
<!--                      class="my-kh el-input-user"-->
<!--                      size="small"-->
<!--                  />-->
<!--                </div>-->
<!--              </span>-->
<!--            </span>-->
<!--          </el-tree>-->
<!--        </el-col>-->
      </el-row>
      <!-- end row -->
    </el-form>
    <!-- end form -->

    <!--starting components modal  -->
    <el-drawer
        class="popups_title"
        :append-to-body="true"
        :visible.sync="tabAddRole"
        direction="rtl"
        size="75%"
        :wrapperClosable="false"
    >
      <AddRole></AddRole>
    </el-drawer>
    <!--starting components modal  -->
  </div>
</template>

<script>
import AddRole from "./components/addRole.vue";
import { mapGetters, mapActions } from "vuex";
import { log } from "util";
export default {
  data() {
    return {
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
      DoctorServices: [],
      services: [],
      loadingButton: false,
      tabAddRole: false,
      checkPhoto: null,
      roles: "",
      specialties: [],
      form: {},
      data: [],
      fileList: [
        {
          name: "food.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  computed: {
    ...mapGetters({
      rules: "users/rules",
      list: "users/list",
      services_list: "services/relation_list",
      columns: "users/columns",
      pagination: "users/pagination",
      statues: "users/statues",
      filter: "users/filter",
      sort: "users/sort",
      ownerBranches: "ownerBranches/list",
      branches: "branches/inventory",
      specialties_list: "specialties/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    await this.servicesList({ relation: true }).then((res) => {
      this.data = res.data.services;
    });
    this.getOwnerBranch();
    this.getBranch();
    if (this.roles == "") await this.Roles();
    if (this.specialties_list.length == 0) await this.Specialties();
  },
  methods: {
    ...mapActions({
      loadRoles: "roles/index",
      servicesList: "services/relationList",
      UserSave: "users/store",
      getBranch: "branches/inventory",
      getOwnerBranch: "ownerBranches/index",
      specialtiesList: "specialties/index",
    }),
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          var formData = new FormData();
          for (var key in this.form) {
            if (this.form[key] != null) {
              formData.append(key, this.form[key]);
            } else {
              formData.append(key, "");
            }
          }
          formData.append("specialties", JSON.stringify(this.specialties));
          // formData.append(
          //     "doctor_services",
          //     JSON.stringify(this.DoctorServices)
          // );
          // formData.append(
          //     "services",
          //     JSON.stringify(this.$refs.items.getCheckedNodes())
          // );
          // formData.append("secondaries", JSON.stringify(this.data));
          this.loadingButton = true;
          this.UserSave(formData)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res.message);
                this.form = {};
                this.DoctorServices = [];
                this.specialties = [];
                this.data = [];
                this.services = [];
                this.$parent.$parent.listChanged();
                this.resetForm("form");
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$notify({
                  title: "Ошибка",
                  type: "error",
                  offset: 130,
                  message: err.message,
                });
              });
        }
      });
    },

//////////////////////////////////////////////

    changes(node, data) {
      this.updateChildren(data.children, data.amount_type, data.amount);
    },
    secondaryChanges(node, data) {
      this.secondaryUpdateChildren(
          data.children,
          data.secondary_amount_type,
          data.secondary_amount
      );
    },
    secondaryUpdateChildren(children, amountType, amount) {
      children.forEach((value) => {
        value.secondary_amount_type = amountType;
        value.secondary_amount = amount;
        if (value.children && value.children.length > 0) {
          this.secondaryUpdateChildren(value.children, amountType, amount);
        }
      });
    },
    updateChildren(children, amountType, amount) {
      children.forEach((value) => {
        value.amount_type = amountType;
        value.amount = amount;
        if (value.children && value.children.length > 0) {
          this.updateChildren(value.children, amountType, amount);
        }
      });
    },

//////////////////////////////////////////////

    Roles() {
      this.loadRoles()
          .then((res) => {
            this.roles = res.data.roles;
          })
          .catch((err) => {});
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    Specialties() {
      this.specialtiesList()
          .then((res) => {})
          .catch((err) => {});
    },
    uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.checkPhoto = true;
        this.form.image = event.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#image").attr("src", e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
    selected() {
      this.DoctorServices = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            this.DoctorServices.push(element.id);
          }
        }
      }
    },
  },
  components: {
    AddRole,
  },
};
</script>

<style>
input[type="file"] {
  height: 100px;
  font-size: 0;
  -webkit-appearance: none;
  appearance: none;
}
input[type="file"]::-webkit-file-upload-button {
  width: 100px;
  height: 100px;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}
#custom-upload {
  display: inline-block;
}
#custom-upload img {
  height: 70px;
}
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 96%;
  display: inline-table !important;
}

.filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.filter-tree .el-tree-node__content {
  height: 40px;
}
.el-input-user {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5715;
  color: #606266;
  background-color: #f5f7fa;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Styling when the input is focused */
.el-input-user:focus {
  outline: 0;
  border-color: #409eff;
  box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}
</style>